var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-schedule-item" }, [
    _vm.isOfType(null)
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type empty-item",
            on: {
              click: function($event) {
                return _vm.$emit("clicked", _vm.date, _vm.direction, "empty")
              }
            }
          },
          [
            _c("el-card", { attrs: { shadow: "never" } }, [
              _c(
                "div",
                { staticClass: "user-schedule-item-body" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: {
                      "font-size": "25px",
                      color: "rgba(0,0,0,0.6)"
                    },
                    attrs: { icon: ["fas", "plus"] }
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.direction == "go"
                          ? "Set trip to college"
                          : "Set trip back home"
                      )
                    )
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      : _vm.isOfType("drive")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type drive-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-icon" }, [
                    _c("img", { attrs: { src: _vm.icon("drive", "black") } })
                  ]),
                  _c("div", { staticClass: "schedule-info" }, [
                    _c("span", { staticClass: "mode" }, [_vm._v("Drive")])
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _vm.isOfType("walk")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type walk-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-icon" }, [
                    _c("img", { attrs: { src: _vm.icon("walk") } })
                  ]),
                  _c("div", { staticClass: "schedule-info" }, [
                    _c("span", { staticClass: "mode" }, [_vm._v("Walk")])
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _vm.isOfType("cycle")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type cycle-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-icon" }, [
                    _c("img", { attrs: { src: _vm.icon("cycle") } })
                  ]),
                  _c("div", { staticClass: "schedule-info" }, [
                    _c("span", { staticClass: "mode" }, [_vm._v("Cycle")])
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _vm.isOfType("shuttle")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type shuttle-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-info" }, [
                    _c(
                      "span",
                      {
                        staticClass: "mode",
                        staticStyle: { "margin-top": "-3px" }
                      },
                      [_vm._v("Getting a shuttle")]
                    ),
                    _c("span", { staticClass: "times" }, [
                      _vm._v("16:30 - 17:10")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "schedule-option-container" }, [
                  _c("div", { staticClass: "schedule-option" }, [
                    _c("div", { staticClass: "image" }, [
                      _c("img", { attrs: { src: _vm.icon("shuttle") } })
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("span", { staticClass: "main" }, [
                        _vm._v("Route S-13")
                      ]),
                      _c("span", { staticClass: "other" }, [
                        _vm._v("Greystones")
                      ])
                    ]),
                    _c("div", { staticClass: "action" }, [
                      _c(
                        "a",
                        [
                          _c("font-awesome-icon", {
                            staticStyle: {
                              "font-size": "30px",
                              color: "rgb(100, 100, 100)"
                            },
                            attrs: { icon: ["fas", "qrcode"] }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _vm.isOfType("carpooling")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type lift-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-info" }, [
                    _c(
                      "span",
                      {
                        staticClass: "mode",
                        staticStyle: { "margin-top": "-3px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.data.type == "carpooling.driver"
                              ? "Driving"
                              : "Getting a lift"
                          )
                        )
                      ]
                    ),
                    _c("span", { staticClass: "times" }, [
                      _vm._v(
                        _vm._s(
                          _vm.direction == "go" ? "To college" : "Back home"
                        )
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "schedule-option-container" },
                  _vm._l(_vm.data.options, function(option, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "schedule-option" },
                      [
                        _c(
                          "div",
                          { staticClass: "image" },
                          [
                            _c("el-avatar", {
                              attrs: {
                                size: 50,
                                src: _vm.getUserPhoto(option.user, "128")
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("span", { staticClass: "main" }, [
                            _vm._v(_vm._s(option.display_name))
                          ]),
                          _vm.data.type == "carpooling.passenger"
                            ? _c("span", { staticClass: "other" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.format_car_reg_plate(
                                      (_vm.data.metadata &&
                                        _vm.data.metadata.car_reg_plate) ||
                                        ""
                                    )
                                  )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "action" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.sendWhatsapp(option.phone_number)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: {
                                  "font-size": "30px",
                                  color: "rgb(79, 206, 93)"
                                },
                                attrs: { icon: ["fab", "whatsapp"] }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      : _vm.isOfType("public_transport")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type public_transport-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-icon" }, [
                    _c("img", { attrs: { src: _vm.icon("public_transport") } })
                  ]),
                  _c("div", { staticClass: "schedule-info" }, [
                    _c("span", { staticClass: "mode" }, [
                      _vm._v("Public transport")
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _vm.isOfType("telecommute")
      ? _c(
          "div",
          {
            staticClass: "user-schedule-item-type telecommute-item",
            on: {
              click: function($event) {
                return _vm.$emit(
                  "clicked",
                  _vm.date,
                  _vm.direction,
                  _vm.data.type
                )
              }
            }
          },
          [
            _c(
              "el-card",
              {
                attrs: { "body-style": { padding: "15px" }, shadow: "always" }
              },
              [
                _c("div", { staticClass: "user-schedule-item-body" }, [
                  _c("div", { staticClass: "schedule-icon" }, [
                    _c("img", { attrs: { src: _vm.icon("telecommute") } })
                  ]),
                  _c("div", { staticClass: "schedule-info" }, [
                    _c("span", { staticClass: "mode" }, [
                      _vm._v("Work from home")
                    ])
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _c("div", [_vm._v("working...")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }