<template>
  <div id="schedule_page">
    <div class="container">
      <div v-for="(v, i) in schedule_days_of_week" :key="i" class="schedule-daily-item">
        <span class="schedule-title">{{v.label}}</span>
        <user-schedule-item :date="v.date" direction="go" :data="schedule && schedule[v.date] && schedule[v.date]['go'] || {}"  @clicked="onScheduleClicked"></user-schedule-item>
        <user-schedule-item :date="v.date" direction="back" :data="schedule && schedule[v.date] && schedule[v.date]['back'] || {}" @clicked="onScheduleClicked"></user-schedule-item>
      </div>
    </div>

    <modal v-model="modal_active" title="Set commuting trip">
      <div v-loading="loading">
        <div v-if="modal_info.type == 'empty'">
          <form-item label="Carpooling with">
            <el-select no-data-text="You have no connections yet" v-model="modal_info.user" placeholder="Who are you carpooling with?">
              <el-option v-for="friend in carpooling_friends" :key="friend.id" :label="`${friend.data.first_name} ${friend.data.last_name}`" :value="friend.id"></el-option>
            </el-select> 
          </form-item>

          <form-item label="I will be a...">
            <el-select v-model="modal_info.role" placeholder="Driver or passenger">
              <el-option v-if="carpooling_details.driver" label="Driver" value="driver"></el-option>
              <el-option v-if="carpooling_details.passenger" label="Passenger" value="passenger"></el-option>
            </el-select> 
          </form-item>
          
          <el-button @click="setTrip" type="primary" style="margin-top: 10px;" class="secondary-color w100">Set trip</el-button>
        </div>

        <div @click="$router.push(`/carpooling-profile/${schedule[modal_info.date][modal_info.direction].options[0].user}`)" v-if="modal_info.type == 'carpooling.passenger'" class="modal-option">
          <div class="modal-option-icon">
            <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
          </div>

          <span class="modal-option-label">View profile</span>
        </div>

        <div @click="cancelTrip(modal_info.date, modal_info.direction, modal_info.type)" v-if="modal_info.type == 'carpooling.driver' || modal_info.type == 'carpooling.passenger'" class="modal-option">
          <div class="modal-option-icon">
            <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
          </div>

          <span class="modal-option-label">Cancel trip</span>
        </div>


      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal/Modal';
import UserScheduleItem from '@/components/Schedule/UserScheduleItem';
import firebase from '@/firebase';
import moment from 'moment';
import FormItem from '@/components/Form/FormItem';

export default {
  components: { Modal, UserScheduleItem, FormItem },

  data() {
    return {
      loading: false,
      modal_active: false,
      temp: {},
      modal_info: {
        date: null,
        direction: null,
        type: null, /* 'empty', '' */
        user: null,
        role: null
      },
      modal_active: false,
      schedule_days_of_week: []
    }
  },

  methods: {
    onScheduleClicked(date, direction, type) {
      this.modal_info.date = date;
      this.modal_info.direction = direction;
      this.modal_info.type = type;


      if (type != 'shuttle') {
        this.modal_active = true;
      } else if (type == 'shuttle') {
        this.$router.push({ name: 'shuttle_ticket', params: { id: '123' }});
      }

      // Carpooling
      if (type == 'carpoooling.passenger' || type == 'carpooling.driver') {
        this.modal_active = true;
        
      }
    },

    // Set the days of the week in order
    setDaysOfWeek() {
      this.schedule_days_of_week = [
        {date: '', label: "Sunday"},
        {date: '', label: "Monday"},
        {date: '', label: "Tuesday"},
        {date: '', label: "Wednesday"},
        {date: '', label: "Thursday"},
        {date: '', label: "Friday"},
        {date: '', label: "Saturday"}
      ];

      // Re-organice array
      for (let i = 0; i < moment().day(); i++) {
        this.schedule_days_of_week.push(this.schedule_days_of_week.shift());
      }

      // Add the YYYY-MM-DD dates to items
      for (let i = 0; i < this.schedule_days_of_week.length; i++) {
        this.schedule_days_of_week[i].date = moment().add(i, "days").format("YYYY-MM-DD");
      }

      // Rename today and tomorrow
      this.schedule_days_of_week[0].label = `Today`;
      // this.schedule_days_of_week[1] = `Tomorrow`;
    },

    setTrip() {
      if (!this.modal_info.user || !this.modal_info.date || !this.modal_info.direction || !this.modal_info.role) {
        return this.$message({ type: 'error', showClose: true, message: 'Fill in all the details' });
      }

      this.loading = true;

      let form_data = { 
        friend_id: this.modal_info.user, 
        date: moment.utc(this.modal_info.date, 'YYYY/MM/DD').toISOString(), 
        direction: this.modal_info.direction,
        role: this.modal_info.role 
      };

      this.SharoAPI({ action: 'bookCarpoolingTripFromFriend', data: form_data }).then(result => {
        if (result.data.error) {
          return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        }

        this.modal_active = false;
        this.$message({ type: 'success', showClose: true, message: 'Trip booked successfully' });
      }).finally(_ => {
        this.loading = false;
      });
    },

    cancelTrip(date, direction, type) {
      if (type != 'carpooling.driver' && type != 'carpooling.passenger') {
        return this.modal_info.active = false;
      }

      this.loading = true;
      console.log({ date: moment.utc(date, 'YYYY-MM-DD').toISOString(), direction: direction });

      this.SharoAPI({ action: 'cancelCarpoolingTripFromSchedule', data: { date: moment.utc(date, 'YYYY-MM-DD').toISOString(), direction: direction } }).then(result => {
        if (result.data.error) {
          return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        }

        this.$message({ type: 'success', showClose: true, message: result.data.message });
        this.modal_active = false;

      }).finally(_ => this.loading = false);
    }
  },

  mounted() {
    this.setDaysOfWeek();
  },

  computed: {
    schedule() {
      return this.$store.getters.userSchedule || {};
    },

    carpooling_friends() {
      return this.$store.getters.carpooling_friends.filter(f => f.friendship_details == 'accepted') || [];
    },

    carpooling_details() {
      return this.$store.getters.user.data.carpooling_details || {};
    }
  }

}
</script>

<style lang="scss" scoped>

#schedule_page .container {
  max-width: 600px;
}

.el-col {
  margin-bottom: 10px;
}

.schedule-title {
  display: block;
  font-size: 22px;
  margin-bottom: 5px;
}

.schedule-daily-item {
  margin-bottom: 40px;
}

.form-item {
  margin-bottom: 10px;
}
</style>