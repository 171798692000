<template>
  <div class="user-schedule-item">
    <!-- No trip set -->
    <div v-if="isOfType(null)" @click="$emit('clicked', date, direction, 'empty')" class="user-schedule-item-type empty-item">
      <el-card shadow="never">
        <div class="user-schedule-item-body">
          <font-awesome-icon
            style="font-size: 25px; color: rgba(0,0,0,0.6)"
            :icon="['fas', 'plus']"
          />
          <span>{{ direction == 'go' ? 'Set trip to college' : 'Set trip back home' }}</span>
        </div>
      </el-card>
    </div>

    <!-- Driving trip set -->
    <div v-else-if="isOfType('drive')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type drive-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-icon">
            <img :src="icon('drive', 'black')" />
          </div>

          <div class="schedule-info">
            <span class="mode">Drive</span>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Walking trip set -->
    <div v-else-if="isOfType('walk')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type walk-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-icon">
            <img :src="icon('walk')" />
          </div>

          <div class="schedule-info">
            <span class="mode">Walk</span>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Cycle trip set -->
    <div v-else-if="isOfType('cycle')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type cycle-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-icon">
            <img :src="icon('cycle')" />
          </div>

          <div class="schedule-info">
            <span class="mode">Cycle</span>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Shuttle trip set -->
    <div v-else-if="isOfType('shuttle')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type shuttle-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-info">
            <span class="mode" style="margin-top: -3px">Getting a shuttle</span>
            <span class="times">16:30 - 17:10</span>
          </div>
        </div>

        <div class="schedule-option-container">
          <div class="schedule-option">
            <div class="image">
              <img
                :src="icon('shuttle')"
              />
            </div>
            <div class="info">
              <span class="main">Route S-13</span>
              <span class="other">Greystones</span>
            </div>
            <div class="action">
              <a>
                <font-awesome-icon
                  style="font-size: 30px; color: rgb(100, 100, 100);"
                  :icon="['fas', 'qrcode']"
                ></font-awesome-icon>
              </a>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Lift trip set -->
    <div v-else-if="isOfType('carpooling')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type lift-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-info">
            <span class="mode" style="margin-top: -3px">{{ data.type == 'carpooling.driver' ? 'Driving' : 'Getting a lift' }}</span>
            <span class="times">{{ direction == 'go' ? 'To college' : 'Back home' }}</span>
          </div>
        </div>

        <div class="schedule-option-container">
          <div v-for="(option, key) in data.options" :key="key" class="schedule-option">
            <div class="image">
              <el-avatar :size="50" :src="getUserPhoto(option.user, '128')"></el-avatar>
            </div>
            <div class="info">
              <span class="main">{{ option.display_name }}</span>
              <!-- <span v-if="data.type == 'carpooling.driver'" class="other">{{ datetime(data.time.start) }} - {{ datetime(data.time.end) }}</span> -->
              <span v-if="data.type == 'carpooling.passenger'" class="other">{{ format_car_reg_plate(data.metadata && data.metadata.car_reg_plate || '') }}</span>
            </div>
            <div class="action">
              <a @click.stop="sendWhatsapp(option.phone_number)">
                <font-awesome-icon
                  style="font-size: 30px; color: rgb(79, 206, 93);"
                  :icon="['fab', 'whatsapp']"
                ></font-awesome-icon>
              </a>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Public transport trip set -->
    <div v-else-if="isOfType('public_transport')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type public_transport-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-icon">
            <img :src="icon('public_transport')" />
          </div>

          <div class="schedule-info">
            <span class="mode">Public transport</span>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Telecommute trip set -->
    <div v-else-if="isOfType('telecommute')" @click="$emit('clicked', date, direction, data.type)" class="user-schedule-item-type telecommute-item">
      <el-card :body-style="{ padding: '15px' }" shadow="always">
        <div class="user-schedule-item-body">
          <div class="schedule-icon">
            <img :src="icon('telecommute')" />
          </div>

          <div class="schedule-info">
            <span class="mode">Work from home</span>
          </div>
        </div>
      </el-card>
    </div>

    <div v-else>working...</div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    date: { type: String },
    direction: { type: String }
  },

  data() {
    return {};
  },

  methods: {
    isOfType(type) {
      if (type == null) {
        return Object.keys(this.data).length < 1;
      } else {
        return Object.keys(this.data).length > 0 && (this.data.type || '').split('.')[0] == type;
      }
    },

    icon(type, color = 'white') {
      return `${process.env.BASE_URL}img/activity-icons/${type}_${color}.png`;
    },

    datetime(datetime) {
      return moment(datetime).format('HH:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.user-schedule-item-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .schedule-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }

  .schedule-info {
    span {
      display: block;
      font-size: 18px;
    }

    .times {
      font-size: 14px;
    }
  }
}

.user-schedule-item {
  margin-bottom: 10px;

  .empty-item {
    .el-card {
      background: rgba(0, 0, 0, 0.02);
    }

    span {
      display: block;
      margin-left: 18px;
      font-size: 16px;
      opacity: 0.8;
      // width: 100%;
      // text-align: center;
    }
  }

  :not(.empty-item) {
    .el-card {
      box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .schedule-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    border: 1px solid rgb(237, 237, 237);
    border-radius: 3px;
    background-color: rgba(248, 248, 248, 0.3);
    padding: 10px;

    .image {
      margin-right: 15px;
      max-height: 50px;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    .info {
      width: 100%;

      span {
        display: block;
        font-size: 18px;
      }

      .other {
        font-size: 14px;
      }
    }

    .action {
      max-width: 50px;
      width: 100%;
      text-align: center;
    }

    // div {
    //   border: 1px solid blue;
    // }
  }
}

.user-schedule-item .user-schedule-item-type:active {
  border: 1px solid $secondary-color;
  border-radius: 3px;
}
</style>