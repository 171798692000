var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "schedule_page" } },
    [
      _c(
        "div",
        { staticClass: "container" },
        _vm._l(_vm.schedule_days_of_week, function(v, i) {
          return _c(
            "div",
            { key: i, staticClass: "schedule-daily-item" },
            [
              _c("span", { staticClass: "schedule-title" }, [
                _vm._v(_vm._s(v.label))
              ]),
              _c("user-schedule-item", {
                attrs: {
                  date: v.date,
                  direction: "go",
                  data:
                    (_vm.schedule &&
                      _vm.schedule[v.date] &&
                      _vm.schedule[v.date]["go"]) ||
                    {}
                },
                on: { clicked: _vm.onScheduleClicked }
              }),
              _c("user-schedule-item", {
                attrs: {
                  date: v.date,
                  direction: "back",
                  data:
                    (_vm.schedule &&
                      _vm.schedule[v.date] &&
                      _vm.schedule[v.date]["back"]) ||
                    {}
                },
                on: { clicked: _vm.onScheduleClicked }
              })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "modal",
        {
          attrs: { title: "Set commuting trip" },
          model: {
            value: _vm.modal_active,
            callback: function($$v) {
              _vm.modal_active = $$v
            },
            expression: "modal_active"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ]
            },
            [
              _vm.modal_info.type == "empty"
                ? _c(
                    "div",
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Carpooling with" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "no-data-text": "You have no connections yet",
                                placeholder: "Who are you carpooling with?"
                              },
                              model: {
                                value: _vm.modal_info.user,
                                callback: function($$v) {
                                  _vm.$set(_vm.modal_info, "user", $$v)
                                },
                                expression: "modal_info.user"
                              }
                            },
                            _vm._l(_vm.carpooling_friends, function(friend) {
                              return _c("el-option", {
                                key: friend.id,
                                attrs: {
                                  label:
                                    friend.data.first_name +
                                    " " +
                                    friend.data.last_name,
                                  value: friend.id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-item",
                        { attrs: { label: "I will be a..." } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Driver or passenger" },
                              model: {
                                value: _vm.modal_info.role,
                                callback: function($$v) {
                                  _vm.$set(_vm.modal_info, "role", $$v)
                                },
                                expression: "modal_info.role"
                              }
                            },
                            [
                              _vm.carpooling_details.driver
                                ? _c("el-option", {
                                    attrs: { label: "Driver", value: "driver" }
                                  })
                                : _vm._e(),
                              _vm.carpooling_details.passenger
                                ? _c("el-option", {
                                    attrs: {
                                      label: "Passenger",
                                      value: "passenger"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "secondary-color w100",
                          staticStyle: { "margin-top": "10px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.setTrip }
                        },
                        [_vm._v("Set trip")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.modal_info.type == "carpooling.passenger"
                ? _c(
                    "div",
                    {
                      staticClass: "modal-option",
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            "/carpooling-profile/" +
                              _vm.schedule[_vm.modal_info.date][
                                _vm.modal_info.direction
                              ].options[0].user
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-option-icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "user"] }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "modal-option-label" }, [
                        _vm._v("View profile")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.modal_info.type == "carpooling.driver" ||
              _vm.modal_info.type == "carpooling.passenger"
                ? _c(
                    "div",
                    {
                      staticClass: "modal-option",
                      on: {
                        click: function($event) {
                          return _vm.cancelTrip(
                            _vm.modal_info.date,
                            _vm.modal_info.direction,
                            _vm.modal_info.type
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-option-icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "times"] }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "modal-option-label" }, [
                        _vm._v("Cancel trip")
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }